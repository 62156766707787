import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import 'slick-carousel/slick/slick';
import Plyr from 'plyr/dist/plyr.js';

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faCircle } from "@fortawesome/free-solid-svg-icons/faCircle";
import { faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faWordpressSimple } from "@fortawesome/free-brands-svg-icons/faWordpressSimple";

import './sass/main.scss'


// (function( $ ) {
//     // Use $ as usual here, e.g.:
//     $(".slider-wrapper").slick({
//         fade: true,
//         autoplay: true,
//         speed: 300,
//         autoplaySpeed: 3000,
//         arrows: false,
//         pauseOnFocus: false,
//         pauseOnHover: false,
//         swipe: false,
//         touchMove: false
//     });

// })( jQuery );

var currentlyPlayingVideo = null;
var youtubePlayerConfig = {
    fullscreen: { enabled: true, fallback: true, iosNative: true},
    muted: false,
    youtube: { 
        controls: 1,
        nocookie: false,
        rel: 0, 
        showinfo: 0, 
        iv_load_policy: 3, 
        modestbranding: 0
    },
    controls: []
}

function pauseOutOfFrameVideo() {
    if (currentlyPlayingVideo && !currentlyPlayingVideo.fullscreen.active) {
        var navBarHeight = document.getElementById('navbar').offsetHeight;
        var video = currentlyPlayingVideo.elements.container;
        var x = 0,
            y = 0,
            w = video.offsetWidth,
            h = video.offsetHeight,
            r, //right
            b, //bottom
            visibleX, visibleY, visible,
            parent;
    
        parent = video;
        while (parent && parent !== document.body) {
            x += parent.offsetLeft;
            y += parent.offsetTop;
            parent = parent.offsetParent;
        }
    
        r = x + w;
        b = y + h;
    
        visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset));
        visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset))-navBarHeight;
    
        visible = visibleX * visibleY / (w * h);
    
        if (visible <= 0) {
            if (!currentlyPlayingVideo.paused) {
                currentlyPlayingVideo.pause();
                currentlyPlayingVideo = null;
            }
        } 
    }
}

const simuLyveCommercialTileContainer = document.querySelector("#simulyveCommercial");
const simuLyveCommercialTile = document.querySelector("#simulyveCommercialTile");
const simuLyveCommercialPlayer = new Plyr('#simulyveCommercialVideo', youtubePlayerConfig);
window.playCommercialVideo = function() {
    simuLyveCommercialTileContainer.classList.add("playing");
    simuLyveCommercialTile.classList.add("playing");
    simuLyveCommercialPlayer.currentTime = 0;
    simuLyveCommercialPlayer.play();
    simuLyveCommercialPlayer.muted = false;
}


window.addEventListener('scroll', pauseOutOfFrameVideo, false);
window.addEventListener('resize', pauseOutOfFrameVideo, false);

// Scrolling helpers
function getOffset( el ) {
    var _x = 0;
    var _y = 0;
    while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}

const scrollTo = function(id) {
    var navBarHeight = document.getElementById('navbar').offsetHeight;
    var elementPosition = getOffset(document.getElementById(id));
    window.scroll({top: elementPosition.top-navBarHeight, left: 0, behavior: 'smooth'});
}
window.scrollTo = scrollTo;

// Manage re-scrolling back to position on covid box close
// var lastScrollOffset = 0;
// $("#covidNewsflash").on("hidden.bs.collapse", function() {
//     if (lastScrollOffset != 0) {
//         window.scroll({top: lastScrollOffset, left: 0, behavior: 'smooth'})
//     }
// });
// document.getElementById("covidNewsflashButton").addEventListener("click", function(event) {
//     // Prevent clicks on the newsflash link from resetting scroll position
//     lastScrollOffset = window.scrollY;
//     scrollTo("top");
// });

// Forms
window.submitContactForm = function() {
    var frm = document.getElementById('contactForm');
    if (frm.checkValidity()) {
        frm.submit(); // Submit the form
        frm.reset();  // Reset all form data
        $('#contactUsModal').modal('hide');
        return false; // Prevent page refresh
    }
}

window.submitRequestDemoForm = function() {
    var frm = document.getElementById('requestDemoForm');
    if (frm.checkValidity()) {
        frm.submit(); // Submit the form
        frm.reset();  // Reset all form data
        $('#requestDemoModal').modal('hide');
        return false; // Prevent page refresh
    }
}


// Load icons
library.add(faBars, faPlay, faLinkedin, faFacebookF, faYoutube, faTwitter, faArrowLeft, faArrowRight, faCircle, faChevronRight, faWordpressSimple);
dom.watch(); 